/* eslint-disable import/no-extraneous-dependencies */
import React, { type CSSProperties, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
  InputRightElement,
  Spinner
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { Eye, EyeSlash } from 'phosphor-react';

interface InputFormProps extends InputProps {
  name: string | undefined | any;
  label?: string | undefined;
  placeholder?: string | undefined;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  buttonRight?: React.ReactNode;
  mask?: string;
  labelStyles?: CSSProperties;
  buttonStyles?: CSSProperties;
  isRequired?: boolean;
  isLoading?: boolean;
}

export const InputForm = ({
  name,
  label,
  iconLeft,
  iconRight,
  buttonRight,
  type = 'text',
  mask,
  labelStyles,
  buttonStyles,
  isRequired = false,
  isLoading,
  ...rest
}: InputFormProps) => {
  const [show, setShow] = useState(false);
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const error = _.get(errors, name);
  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={5}>
      {label && (
        <FormLabel style={labelStyles} fontWeight="bold" mb={1} mr={3} htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputGroup alignItems="center" alignContent="center">
            {iconLeft && (
              <InputLeftElement pointerEvents="none" fontSize="1.2em">
                {iconLeft}
              </InputLeftElement>
            )}

            {iconRight && (
              <InputRightElement pointerEvents="none" fontSize="1.2em">
                {iconRight}
              </InputRightElement>
            )}

            <Input
              id={name}
              py={5}
              px={4}
              _placeholder={{ color: '#A2A5A8' }}
              type={!show ? type ?? 'text' : 'text'}
              as={!mask ? undefined : InputMask}
              mask={mask ?? ''}
              maskChar=""
              {...field}
              {...rest}
            />

            {isLoading && (
              <InputRightElement width="4.5rem" height="74%">
                <Spinner color="primary.500" />
              </InputRightElement>
            )}

            {type === 'password' && (
              <InputRightElement width="4rem" height="full" mr="-12px">
                <Icon
                  onClick={() => {
                    setShow((m) => !m);
                  }}
                  fontSize="25px"
                  cursor="pointer"
                  style={buttonStyles}
                  _focus={{ outline: 'none' }}
                  border="none">
                  {show ? <Eye size={20} /> : <EyeSlash size={20} />}
                </Icon>
              </InputRightElement>
            )}

            {buttonRight && buttonRight}
          </InputGroup>
        )}
      />

      {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
    </FormControl>
  );
};
