import { type ReactNode } from 'react';
import { VStack } from '@chakra-ui/react';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <VStack minH="100vh">
      <header style={{ width: '100%', height: '1.5vh', backgroundColor: '#F6A304' }} />
      <VStack flex={1} w="100%" display="flex" flexDirection="column">
        {children}
      </VStack>
      <footer style={{ width: '100%', height: '11vh', backgroundColor: '#035479' }} />
    </VStack>
  );
};

export default Layout;
