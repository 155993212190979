import { Box, Button, Container, Flex, Image, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../components/layout';
import LogoBe from '../../assets/logo.png';
import LogoGov from '../../assets/logos.png';
import LogoAgir from '../../assets/logo_agir.png';
import { InputForm } from '../../components/InputForm';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { isCPF } from 'validation-br';
import { yupResolver } from '@hookform/resolvers/yup';
// import { DateInputForm } from '../../components/DateInputForm';
import { useAuth } from '../../providers/auth';
import { type ErrorType } from '../../hooks/useFetch';
import moment from 'moment';
import { parse } from 'date-fns';

const defaultValues = {
  cpf: '',
  birthday: ''
};

const schema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo obrigatório!')
    .test('cpf', 'CPF inválido', (val) => val !== undefined && isCPF(val)),
  birthday: Yup.date()
    .required('Campo obrigatório!')
    .transform(function (value, originalValue) {
      const parsedDate =
        originalValue.length === 10 ? parse(originalValue, 'dd/MM/yyyy', new Date()) : false;

      return parsedDate;
    })
    .typeError('Data inválida')
});

const LoginPage: React.FC = () => {
  const { Login } = useAuth();
  const toast = useToast();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      cpf: '',
      birthday: '' as any
    }
  });

  const handleSubmit = async (values: typeof defaultValues) => {
    try {
      await Login({
        username: values.cpf,
        password: 'password',
        birth_date: moment(values.birthday, 'DD/MM/yyyy').format('yyyy-MM-DD')
      });
      // await Login({ username: '053.984.711-98', password: '123456' });
      toast({
        title: 'Login realizado com sucesso!',
        status: 'success',
        duration: 10000,
        isClosable: true,
        position: 'top'
      });
    } catch (err) {
      const error = err as ErrorType;
      toast({
        title: 'Erro ao executar a ação.',
        description: error?.response?.data?.msg,
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top'
      });
    }
  };

  return (
    <Layout>
      <Container flex={1} display="flex" flexDirection="column" maxW={['90vw', '50vw', '30vw']}>
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" gap="30px">
          <Image src={LogoGov} />
          <FormProvider {...formMethods}>
            <form noValidate onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Box flex={1} display="flex" flexDirection="column" gap={5}>
                <InputForm
                  name="cpf"
                  label="CPF"
                  placeholder="xxx.xxx.xxx-xx"
                  mask="999.999.999-99"
                />
                <InputForm
                  name="birthday"
                  label="Data de nascimento"
                  placeholder="xx/xx/xxxx"
                  mask="99/99/9999"
                />
                {/* <DateInputForm
                  name="birthday"
                  label="Data de nascimento"
                  placeholder="xx/xx/xxxx"
                  maxDate={new Date()}
                /> */}

                <Button
                  isDisabled={formMethods.formState.isSubmitting}
                  isLoading={formMethods.formState.isSubmitting}
                  width="full"
                  mt="20px"
                  py={4}
                  backgroundColor="#035479"
                  color="#FFF"
                  type="submit">
                  <Text fontWeight="normal">ENTRAR</Text>
                </Button>
              </Box>
            </form>
          </FormProvider>
          <Flex justifyContent="space-between" alignItems="center">
            <Image h="40px" src={LogoAgir} />
            <Image h="70px" src={LogoBe} />
          </Flex>
        </Box>
      </Container>
    </Layout>
  );
};

export default LoginPage;
