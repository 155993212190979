import { Box, Button, Container, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAuth } from '../../providers/auth';
import { CheckCircle } from 'phosphor-react';

import sound from '../../assets/sound.mp3';

const SuccessPage: React.FC = () => {
  const audio = new Audio(sound);
  const { Logout } = useAuth();
  const handleRedirect = () => {
    Logout();
  };

  useEffect(() => {
    audio.play();
    setTimeout(handleRedirect, 10000);
  }, []);

  return (
    <Container flex={1} display="flex" flexDirection="column" maxW={['90vw', '50vw', '30vw']}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap="30px"
        py="10vh">
        <div>
          <Text textAlign="center" fontSize="16px" fontStyle="italic">
            &quot;Incrível como você se supera a cada desafio. Estou realmente impressionado com o
            que você alcançou. Continue assim!&quot;
          </Text>
        </div>

        <Flex flexDir="column" textAlign="center" alignItems="center" gap="30px">
          <Flex flexDir="column" alignItems="center" gap="10px">
            <CheckCircle size={100} color="green" />

            <Text fontSize="18px">Plantão validado com sucesso!</Text>
          </Flex>
          <Text fontStyle="italic">Em 10 segundos você será redirecionado para a tela inicial</Text>

          <Button onClick={handleRedirect} py={4} backgroundColor="#035479" color="#FFF">
            <Text fontWeight="normal">Ir para tela inicial</Text>
          </Button>
        </Flex>

        <div />
      </Box>
    </Container>
  );
};

export default SuccessPage;
