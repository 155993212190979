import axios from 'axios';

const apiUrl = {
  hml: 'https://api.apps.plantaoextra.com',
  // eslint-disable-next-line no-undef
  prod: process.env.REACT_APP_API_URL
};

export const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? apiUrl.prod : apiUrl.hml,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);
