import { Box, Button, Flex, Text, Wrap, WrapItem, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { type ErrorType, useFetch } from '../../hooks/useFetch';
import moment from 'moment';
import CardComponent from './components/Card';
import { useAuth } from '../../providers/auth';
import { useNavigate } from 'react-router-dom';
import { CalendarCheck } from 'phosphor-react';

const CheckInPage: React.FC = () => {
  const navigate = useNavigate();
  const { user, Logout } = useAuth();
  const toast = useToast();
  const { requestApi, data, loading } = useFetch('/v2/client/agendas/scales');
  const { requestApi: requestApiCheck, loading: loadingCheck } = useFetch('');

  const handleClick = async (schedule) => {
    try {
      await requestApiCheck(
        'put',
        { type: !!schedule?.check_in && !schedule.check_out ? 'end' : 'start' },
        `/v2/client/agendas/${schedule.id}/point`
      );
      navigate('/user/check-in/success');
    } catch (err) {
      const error = err as ErrorType;
      toast({
        title: 'Erro ao executar a ação.',
        description: error?.response?.data?.msg,
        status: 'error',
        duration: 10000,
        isClosable: true
      });
    }
  };

  const handleRedirect = () => {
    Logout();
  };

  useEffect(() => {
    requestApi('get', {
      params: {
        date_start: moment().subtract(1, 'days').format('yyyy-MM-DD'),
        date_end: moment().format('yyyy-MM-DD')
      }
    });
  }, []);

  useEffect(() => {
    if (!loading && !!data && data?.data?.length === 0) setTimeout(handleRedirect, 10000);
  }, [data?.data]);

  return (
    <Box flex={1} w="90%" py="50px" display="flex" flexDirection="column" justifyContent="center">
      <Wrap spacing="30px" justify="center">
        {data?.data?.filter(
          (item) => item?.agenda?.check_out === null || item?.agenda?.validated_status === 0
        ).length > 0 ? (
          data?.data
            .filter(
              (item) => item?.agenda?.check_out === null || item?.agenda?.validated_status === 0
            )
            .map((item, index) => (
              <WrapItem key={index}>
                <CardComponent
                  item={item}
                  user={user}
                  onClick={() => {
                    handleClick(item.agenda);
                  }}
                  buttonDisabled={loadingCheck}
                />
              </WrapItem>
            ))
        ) : (
          <Flex flexDir="column" textAlign="center" alignItems="center" gap="30px">
            <Flex flexDir="column" alignItems="center" gap="10px">
              <CalendarCheck size={100} color="#035479" />

              <Text fontSize="18px">Nenhum plantão agendado.</Text>
            </Flex>
            <Text fontStyle="italic">
              Em 10 segundos você será redirecionado para a tela inicial
            </Text>

            <Button onClick={handleRedirect} py={4} backgroundColor="#035479" color="#FFF">
              <Text fontWeight="normal">Ir para tela inicial</Text>
            </Button>
          </Flex>
        )}
      </Wrap>
    </Box>
  );
};

export default CheckInPage;
