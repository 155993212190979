import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

interface Props {
  item: any;
  user: any;
  onClick: () => void;
  buttonDisabled: boolean;
}

const CardComponent: React.FC<Props> = ({ item, user, onClick, buttonDisabled }) => {
  return (
    <Flex
      padding="20px"
      flexDirection="column"
      textAlign="center"
      align="center"
      justify="space-between"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px #00000040"
      w={['250px', '298px']}
      minH="400px">
      <Avatar src={user.image_url} size="2xl" border="5px solid #BBE9F9" />

      <Text fontWeight="bold">{user.name}</Text>

      <Box>
        <Text color="#505255">{item.scale.name}</Text>
        <Text fontSize="12px" color="#A2A5A8">
          {moment(item.agenda.date_start).format('HH:mm')} -{' '}
          {moment(item.agenda.date_end).format('HH:mm')}
        </Text>
      </Box>

      <Flex
        width="80%"
        border="1px solid #A2A5A8"
        color="#A2A5A8"
        align="center"
        justify="center"
        py="10px"
        borderRadius="full">
        <Text>{item.company.name}</Text>
      </Flex>

      <Text color="#8B8E93">{item.unit.name}</Text>

      {item.agenda.show_buttons.find(
        (button) => button.name === 'start' || button.name === 'end'
      ) ? (
        <Button
          isDisabled={buttonDisabled}
          onClick={onClick}
          backgroundColor="#BBE9F9"
          border="1px solid #0F83AD"
          color="#0F83AD">
          <Text fontWeight="normal">
            {item.agenda.show_buttons.find((button) => button.name === 'start')
              ? 'Fazer Check-in'
              : 'Fazer Check-out'}
          </Text>
        </Button>
      ) : (
        <Button isDisabled backgroundColor="#BBE9F9" border="1px solid #0F83AD" color="#0F83AD">
          <Text fontWeight="normal">Ação desabilitada</Text>
        </Button>
      )}
    </Flex>
  );
};

export default CardComponent;
