import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/login';
import { RequireAuth } from './require.auth';
import CheckInPage from '../pages/checkIn';
import SuccessPage from '../pages/success';

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/user" element={<RequireAuth />}>
      <Route path="/user/check-in" element={<CheckInPage />} />
      <Route path="/user/check-in/success" element={<SuccessPage />} />
    </Route>
  </Routes>
);

export default AppRouter;
