import { ChakraProvider } from '@chakra-ui/react';
import AppRouter from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './providers/auth';
import { theme } from './theme';

export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </BrowserRouter>
  </ChakraProvider>
);
