import React, { createContext, type ReactElement, useContext, useState } from 'react';
import { type AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';

interface UserImageType {
  large: string;
  medium: string;
  original: string;
  small: string;
}

interface UserType {
  id: number;
  cpf: string;
  cell_phone: string;
  email: string;
  genre: string;
  name: string;
  status: boolean;
  image: UserImageType | null;
}

interface ErrorType {
  status: string;
  msg: string;
}

interface AuthContextData {
  signed: boolean;
  user: UserType | object | any;
  loading: boolean;
  error: ErrorType | string | null | unknown;
  // eslint-disable-next-line no-unused-vars
  Login: (user: object) => Promise<void> | any;
  Logout: () => void;
  getUser: () => any;
}

interface AuxProps {
  children: ReactElement | ReactElement[];
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuxProps> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | object | null>(null);
  const [error, setError] = useState<ErrorType | string | null | unknown>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser: AuthContextData['getUser'] = async () => {
    const res = await api.get('/v1/users/profile');

    if (res?.status !== 200) {
      return false;
    }
    // set user
    setUser(res.data.data);
    return user;
  };

  const Login: AuthContextData['Login'] = async (data) => {
    try {
      setLoading(true);
      setError(null);

      // set header authorization
      api.defaults.headers.common.Authorization = 'cGxhbnRhb2JhY2tvZmZpY2U6YnJ1eW5wYW4=';

      // request
      const res = await api.post('/v1/oauth/token', {
        grant_type: 'password',
        ...data
      });

      if (res?.status === 200) {
        // set token in header
        api.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

        // get user
        await getUser();

        setLoading(false);
        navigate('/user/check-in', { replace: true });
      }
    } catch (e) {
      const err = e as AxiosError;

      setError(err.response?.data);
      setLoading(false);
      throw err;
    }
  };

  function Logout() {
    setUser(null);
  }

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ signed: Boolean(user), user, loading, error, Login, Logout, getUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}
