import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Layout from '../components/layout';
import { useAuth } from '../providers/auth';

export const RequireAuth = () => {
  const { signed, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return null;
  }

  return signed ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
